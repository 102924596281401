@import "./managerQue.css";
@import "./managerEmp.css";
@import "./managerProfile.css";

.manager_wrapper {
  /* padding: 0 0 5rem; */
  height: calc(100vh - 86px);
  overflow-y: auto;
}
.header_manager_secondary {
  padding-top: 2rem;
  width: 100%;
  display: flex;
  background-color: #fff;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.04);
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
}
