.app_header_wrapper {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 4.3%;
  background: #ffffff;
  border-bottom: 1px solid rgba(43, 39, 68, 0.2);
  display: flex;
  justify-content: space-between;
  z-index: 1000;
}
.app_container {
  width: 100%;
  padding: 0 4.3%;
  background: #ffffff;
}
.app_header_logo_container_link {
  display: flex;
  align-items: center;
  height: 100%;
  text-decoration: none;
  font-size: 18px;
  font-weight: 700;
  color: black;
  height: 100%;
  max-height: 80px;
  width: 60px;
}
.app_header_menu {
  display: flex;
  height: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
}
.app_header_menu li:not(:last-child) {
  margin-right: 32px;
}
.app_header_menu_link {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 30px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #2b2744 !important;
  text-decoration: none;
}
.app_header_menu_link:hover {
  color: #2b2744;
}
.app_header_menu_linkActive {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 30px 0;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
    color: #8562fc;
  text-decoration: none;
  border-bottom: 2px solid #8562fc;
}
.app_header_menu_linkActive:hover {
  color: #5153f5;
}
.app_header_user_btn {
  display: flex;
  align-items: center;
  height: 100%;
  background-color: transparent;
  border: none;
  outline: none;
}
.header_user_img_container {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0px 0px 4px rgba(43, 39, 68, 0.1);
  margin: 0 10px 0 0;
}
.header_user_img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.app_header_user_name_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #2b2744;
  display: flex;
  white-space: nowrap;
  margin: 0 4px 0 0;
  padding: 0 2px;
}
.header_dropdownArrow {
  transform: rotate(0);
  transition: all 0.3s ease !important;
}
.header_dropdownArrow.open {
  transform: rotate(180deg);
}
.header_userMenuContainer {
  position: absolute;
  top: 80%;
  right: 8px;
  display: flex;
  width: max-content;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  transform: translateY(-10%);
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  z-index: 100;
  background-color: #fff;
  box-shadow: 0px 6px 12px rgba(91, 86, 117, 0.15);
}
.header_userMenuContainer.show {
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
}
.header_userMenu {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.userMenuIcon {
  margin-right: 16px;
}
.userMenuLink {
  display: flex;
  padding: 12px 40px 12px 16px;
  text-decoration: none;
  color: #0d0e11;
  font-size: 14px;
  font-weight: 500;
}
.userMenuLink:hover {
  background-color: rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  color: #0d0e11;
}
.userMenu_logoutBtn {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 40px 12px 16px;
  border: 0;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background: transparent;
  text-decoration: none;
  color: #e96969;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}
.userMenu_logoutBtn:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: #e96969;
}

@media screen and (min-width: 1400px) {
}
@media screen and (max-width: 1399.99px) {
}
@media screen and (max-width: 1199.99px) {
}
@media screen and (max-width: 991.99px) {
  .app_header_user_name_text,
  .header_dropdownArrow,
  .header_menu_link_text {
    display: none !important;
  }
  .header_user_img_container {
    margin-right: 0;
  }
  .header_menu_link_icon {
    font-size: 20px !important;
  }
  .app_header_menu_linkActive {
    border-bottom: none !important;
  }
}
@media screen and (max-width: 767.99px) {
}
@media screen and (max-width: 575.99px) {
  .app_header_wrapper,
  .app_container {
    padding: 0 12px;
  }
}
@media screen and (max-width: 400px) {
}
@media screen and (max-width: 319.99px) {
}
