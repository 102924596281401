.manager_profile_header {
  width: 100%;
  padding: 2rem 4.3%;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
}
.user_img_container {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0px 0px 4px rgba(43, 39, 68, 0.1);
}
