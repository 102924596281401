:root {
  --color_primary: #5153f5;
  --color_secondary: #e5e5fe;
  --color_success: #27ae60;
  --color_error: #e96969;
  --color_light_bg: #f6f6f9;
}

body {
  font-family: "Inter";
}

.app_bg-secondary {
  background-color: var(--color_light_bg);
}

.app_heading_normal {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 35px;
  line-height: 42px;
  color: #2b2744;
  margin: 0;
}

.app_heading {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 26px;
  line-height: 42px;
  color: #2b2744;
  margin: 0;
}

.app_heading_24_bold {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #2b2744;
  margin: 0;
}

.app_heading_24 {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  color: #2b2744;
  margin: 0;
}

.app_heading_20_bold {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #2b2744;
  margin: 0;
}

.app_heading_30_bold {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 30px;
  line-height: 39px;
  color: #2b2744;
  margin: 0;
}

.app_heading_16 {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 42px;
  color: #2b2744;
  margin: 0;
}

.app_text_14_bold {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #2b2744;
}

.app_text_16_bold {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #2b2744;
}

.app_text_20_bold {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #2b2744;
  margin: 0;
}

.app_text_16_semi_bold {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #2b2744;
}

.app_link {
  text-decoration: none;
  color: var(--color_primary);
}

.app_link:hover {
  color: var(--color_primary);
}

.app_text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #4c4862;
  margin: 0;
}

.app_text_bold {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #2b2744;
  margin: 0;
}

.app_text_dark {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.app_text_secondary_gray {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #9997a6;
}

.app_text_primary {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #5153f5;
}

.app_primary_text_bold {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #5153f5;
}

.app_validation_text {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  margin: 5px 0;
}

.app_validation_text.valid {
  color: var(--color_success);
}

.app_validation_text.invalid {
  color: var(--color_error);
}

.app_primary_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  outline: 0;
  padding: 14px 24px;
  background: #5153f5;
  border-radius: 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
}

.app_btn_danger {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  outline: 0;
  padding: 14px 24px;
  background: #dc3545;
  border-radius: 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
}

.app_primary_btn:hover {
  color: #ffffff;
}

.app_primary_btn.disabled {
  background: #d8dae7;
  pointer-events: none;
}

.app_secondary_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  outline: 0;
  padding: 14px 24px;
  background: var(--color_secondary);
  border-radius: 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--color_primary);
  white-space: nowrap;
}

.app_danger_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  outline: 0;
  padding: 14px 24px;
  background: #fce9e9;
  border-radius: 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--color_error);
}

.app_blank_btn {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  border: 0;
  outline: 0;
  background: transparent;
  padding: 0;
  width: 100%;
}

.app_textInputWrapper {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #e5e6ef;
  border-radius: 8px;
  /* margin-bottom: 32px; */
}

.app_textInputWrapper:focus-within {
  border: 2px solid var(--color_primary) !important;
}

.app_textInputWrapper.error:focus-within {
  border: 2px solid var(--color_error) !important;
  margin-top: -2px;
  margin-left: -2px;
}

.app_textInput {
  width: 100%;
  padding: 12px 10px 12px 1rem;
  border-radius: inherit;
  border: 0;
  outline: none;
}

.app_textInputWrapper:focus-within .app_textInput {
  padding: 11px 10px 11px 15px;
}

.appInput_Placeholder {
  position: absolute;
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  color: #9e98ac;
  left: 0;
  padding: 0 6px;
  margin-left: 1rem;
  transition: all 0.2s ease-out;
  cursor: text;
}

.app_textInput:focus+.appInput_Placeholder {
  transform: translate(0, -175%) scale(0.85);
  background-color: #fff;
  color: var(--color_primary);
}

.app_textInput.error:focus+.appInput_Placeholder {
  transform: translate(0, -175%) scale(0.85);
  background-color: #fff;
  color: var(--color_error);
}

.app_textInput:not(:placeholder-shown)+.appInput_Placeholder {
  transform: translate(0, -175%) scale(0.85);
  background-color: #fff;
  color: var(--color_primary);
}

.app_textInput:not(:focus)+.appInput_Placeholder {
  color: #9e98ac;
}

.app_searchInputWrapper {
  position: relative;
  display: flex;
  border: 1px solid #e5e6ef;
  border-radius: 29px;
  overflow: hidden;
  background-color: #e4e6f2;
}

.app_searchInputWrapper input {
  width: 100%;
  display: flex;
  padding: 1rem 1rem 1rem 42px;
  border-radius: inherit;
  border: none;
  outline: none;
  align-items: center;
  background-color: inherit;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #2b2744;
}

.app_search_icon_wrapper {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 1rem;
  color: #2b2744 !important;
}

.validation_string_container {
  position: absolute;
  top: 100%;
  left: 0;
  display: flex;
  align-items: center;
}

.app_card {
  background: #ffffff;
  /* box-shadow: 0px 1px 2px rgba(43, 39, 68, 0.15); */
  border: 1px solid #e3e3e3;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  min-width: fit-content;
}

.app_border_card {
  background: #ffffff;
  border: 1px solid #e4e6f2;
  box-shadow: 0px 1px 2px rgba(43, 39, 68, 0.1);
  border-radius: 15px;
  padding: 24px;
  display: flex;
}

.app_divider {
  width: 100%;
  margin: 0;
  border-bottom: 1px solid #9997a6;
  background-color: #e4e6f2;
}

.app_dropdown_wrapper {
  position: relative;
  width: 100%;
  display: flex;
}

.app_dropDownBtn {
  background: #ffffff;
  border: 1px solid #e5e6ef;
  border-radius: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 10px 12px 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}

.app_dd_list_wrapper {
  display: flex;
  position: absolute;
  width: 100%;
  top: 100%;
  transform: translateY(-10%);
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  z-index: 100;
  box-shadow: 0px 4px 10px rgba(30, 38, 57, 0.08);
  background-color: #ffffff;
}

.app_dd_list_wrapper.show {
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
}

.app_dd_arrowIcon {
  transform: rotate(0) !important;
  transition: transform 0.2s ease-in-out !important;
}

.app_dd_arrowIcon.open {
  transform: rotate(180deg) !important;
}

.app_dd_list {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 0;
  margin: 0;
  background-color: #ffffff;
  width: 100%;
  box-shadow: 0px 1px 3px rgba(30, 38, 57, 0.08);
  border-radius: 4px;
}

.app_dd_list li {
  display: flex;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}

.app_dd_list li:hover {
  background-color: #e5e6ef;
}

.app_dd_list li:last-child:hover {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.edituserimg_wrapper {
  position: relative;
  width: fit-content;
}

.edituserimg_container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 88px;
  width: 88px;
  border-radius: 50%;
  margin-top: 1rem;
  box-shadow: 0px 6px 10px rgba(43, 39, 68, 0.1);
}

.userEditImgInput_wrapper {
  position: absolute;
  right: 0;
  top: 70%;
  transform: translate(20%, -70%);
}

.userprofile_editImgLabel {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-color: #fff;
  color: #3b37da !important;
  box-shadow: 0px 6px 10px rgba(43, 39, 68, 0.1);
  cursor: pointer;
}

.userprofile_editImgInput {
  display: none;
}

.user_edit_img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  object-position: center;
}

.sitemap_container {
  margin-top: 80px;
}

.sitemap_link_card {
  text-decoration: none;
  padding: 18px 24px;
  display: flex;
  align-items: center;
}

.sitemap_link_card:hover {
  border: 0.1px solid rgba(81, 83, 245, 0.6);
  box-shadow: 0px 5px 20px rgba(43, 39, 68, 0.15);
}

.calender-card-wrapper {
  background: #FFFFFF;
  box-shadow: 0px 1px 2px rgba(43, 39, 68, 0.15);
  border-radius: 15px;
}

.calender_sidebar .app_card {
  padding: 12px 24px;
}

.fc-theme-standard th[role="presentation"],
.fc-theme-standard td[role="presentation"] {
  padding: 0px;
  border: none;
}

.fc-theme-standard td,
.fc-theme-standard th {
  border: 1px solid #E4E6F2;
  padding: 16px 12px;
}

.fc .fc-daygrid-day-number {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  text-decoration: none;
  outline: none;
  padding: 0px;
}

.fc .fc-col-header-cell-cushion {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #2B2744;
  text-decoration: none;
  outline: none;
}

.fc .fc-day-other .fc-daygrid-day-top {
  opacity: 0.27;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: #ffffff;
}

.fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-number {
  color: #5153F5;
  font-weight: 600;
}

.fc .fc-daygrid-day-top {
  flex-direction: row;
}

.fc-theme-standard .fc-scrollgrid {
  border: none;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: unset !important;
  padding: 24px;
  margin: 0px;
  border-bottom: 1px solid #E4E6F2;
}

.fc .fc-toolbar-title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  color: #2B2744;
}

.fc .fc-button-primary {
  background: transparent;
  padding: 0.4em 0.4em !important;
  border-radius: 50px;
  border: none;
  color: #2B2744;
}

.fc .fc-daygrid-event-harness {
  display: inline-block;
}

@media screen and (min-width: 1400px) {}

@media screen and (max-width: 1399.99px) {}

@media screen and (max-width: 1199.99px) {}

@media screen and (max-width: 991.99px) {

  .app_heading,
  .app_heading_normal {
    font-size: 30px;
  }
}

@media screen and (max-width: 767.99px) {

  .app_heading,
  .app_heading_normal {
    font-size: 28px;
  }
}

@media screen and (max-width: 575.99px) {}

@media screen and (max-width: 400px) {

  .app_heading,
  .app_heading_normal {
    font-size: 24px;
  }
}

@media screen and (max-width: 319.99px) {}

.badge-soft-primary {
  color: #405189;
  background-color: rgba(64, 81, 137, .1)
}

.badge-soft-success {
  color: #27AE60;
  background-color: rgba(5, 194, 169, 0.158);
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.badge-soft-danger {
  color: #F16EA5;
  background-color: rgba(226, 115, 211, 0.151);
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.badge-soft-dark {
  color: #212529;
  background-color: rgba(33, 37, 41, .1);
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.badge-soft-secondary {
  color: #3577f1;
  background-color: rgba(31, 85, 187, 0.1);
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.fc-h-event.badge {
  font-family: 'Inter';
  border: none;
  border-radius: 4px;
  font-size: 12px;
  line-height: 16px;
  padding: 4px 6px;
  display: inline-block;
}

.fc-h-event {
  background-color: unset !important;
  border: unset !important;
}

.fc-h-event.badge.badge-success {
  background-color: #E1F2E7;
  color: #27AE60 !important;
  width: 24px;
  height: 24px;
  line-height: 24px;
  padding: 0px;
}

.fc-h-event.badge.badge-success .css-i4bv87-MuiSvgIcon-root {
  font-size: 22px;
}

.fc-h-event.badge.badge-success .fc-event-main {
  color: #27AE60 !important;
}

.fc-h-event.badge.badge-pink {
  background-color: #F9E1ED;
  color: #F16EA5 !important;
}

.fc-h-event.badge.badge-pink .fc-event-main {
  color: #F16EA5 !important;
}

.fc-h-event.badge.badge-gray {
  background-color: rgba(76, 72, 98, 0.1);
}

.fc-h-event.badge.badge-gray .fc-event-main {
  color: #4C4862 !important;
  opacity: 0.4;
}

.badge-outline.badge-primary {
  color: #405189;
  border: 1px solid #405189;
  background-color: transparent
}

.badge-label.bg-primary:before {
  border-left-color: #405189
}

.badge-outline.badge-secondary {
  color: #3577f1;
  border: 1px solid #3577f1;
  background-color: transparent
}

.badge-label.bg-secondary:before {
  border-left-color: #3577f1
}

.badge-outline.badge-success {
  color: #0ab39c;
  border: 1px solid #0ab39c;
  background-color: transparent
}

.badge-label.bg-success:before {
  border-left-color: #0ab39c
}

.badge-soft-info {
  color: #299cdb;
  background-color: rgba(41, 156, 219, .1)
}

.badge-outline.badge-info {
  color: #299cdb;
  border: 1px solid #299cdb;
  background-color: transparent
}

.badge-label.bg-info:before {
  border-left-color: #299cdb
}

.badge-soft-warning {
  color: #f7b84b;
  background-color: rgba(247, 184, 75, .1)
}

.badge-outline.badge-warning {
  color: #f7b84b;
  border: 1px solid #f7b84b;
  background-color: transparent
}

.badge-label.bg-warning:before {
  border-left-color: #f7b84b
}

.badge-outline.badge-danger {
  color: #f06548;
  border: 1px solid #f06548;
  background-color: transparent
}

.badge-label.bg-danger:before {
  border-left-color: #f06548
}

.badge-soft-light {
  color: #f3f6f9;
  background-color: rgba(243, 246, 249, .1)
}

.badge-outline.badge-light {
  color: #f3f6f9;
  border: 1px solid #f3f6f9;
  background-color: transparent
}

.badge-label.bg-light:before {
  border-left-color: #f3f6f9
}

.otp_input_group>div {
  gap: 16px;
}

.otp-input {
  width: 40px;
}

.otp-input input {
  display: block;
  width: 100% !important;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
.fc-daygrid-dot-event:hover,
.fc-daygrid-dot-event.fc-event-mirror {
  background: rgba(0, 0, 0, 0) !important;
}