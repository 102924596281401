.otc {
    position: relative;
    width: 320px;
    margin: 0;
  }
  
  .otc fieldset {
    border: 0;
    padding: 0;
    margin: 0;
  }
  
  .otc fieldset div {
    display: flex;
    align-items: center;
  }
  
  .otc legend {
    margin: 0 auto 1em;
    color: #5555ff;
  }
  
  .forget_number{
    width: 1.2em;
    line-height: 1;
    margin: 0.24em;
    padding: 4px 8px;
    font-size: 2rem;
    text-align: center;
    appearance: textfield;
    -webkit-appearance: textfield;
    border: 2px solid #bbbbff;
    color: purple;
    border-radius: 4px;
  }
  
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  

  input[type="number"]:nth-child(n + 4) {
    order: 2;
  }
  /* .otc div::before {
      content: '';
      height: 2px;
      width: 24px;
      margin: 0 .25em;
      order: 1;
      background: #BBBBFF;
  } */
  
  .otc label {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
  }