@import "antd/dist/antd.css";
@import "/node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "../assets/fonts/stylesheet.css";
@import "./commonStyle.css";
@import "./authenticationStyle/authStyle.css";
@import "./layoutStyle/layout.css";
@import "./managerStyle/manager.css";
@import "./employeeStyle/employee.css";

::-webkit-scrollbar {
  background-color: transparent;
  width: 8px;
  height: 5px;
}
/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: inherit;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  /* border: 4px solid #fff; */
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none;
}

.is-invalid + .validation_text_invalid {
  color: #ff0000;
  font-size: 14px;
}
.appLoader_wrapper {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 250ms ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}



.emp_home_wrapper {
  background: rgb(255,255,255);
background: linear-gradient(45deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 45%, rgba(81, 83, 245, 1) 150%);
}