.que_active_header,
.que_prev_header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 0;
}

.header_manager_secondary_linkList {
  display: flex;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.header_manager_secondary_linkList li {
  height: 100%;
  display: flex;
  margin-right: 2rem;
}

.header_manager_secondary_link {
  display: flex;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2b2744;
  cursor: pointer;
}

.manager_que_header_radio_input {
  display: none;
}

.manager_que_header_radio_input:checked+.header_manager_secondary_link {
  display: flex;
  font-weight: 600;
  padding-bottom: 10px;
  font-size: 16px;
  line-height: 24px;
  color: #5153f5;
  border-bottom: 4px solid #5153f5;
}

.create_que_btn {
  padding: 12px 20px;
  text-decoration: none;
}

.create_que_btn:hover {
  color: #ffffff;
}

.add_btn_with_icon_text {
  margin-left: 8px;
}

.que_card_view_response_btn {
  padding: 16px 24px;
  text-decoration: none;
}

.que_card_view_response_btn:hover {
  color: #5153f5;
}

.manager_que_card {
  display: flex;
  justify-content: space-between;
  padding: 24px;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(43, 39, 68, 0.15);
  border-radius: 15px;
}

.que_card_title {
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #2b2744;
  margin-bottom: 1rem;
}

.que_card_right,
.que_draft_card_right {
  display: flex;
  align-items: center;
}

.manager_que_card_submenu_container {
  position: absolute;
  top: 100%;
  right: 14px;
  background: #ffffff;
  box-shadow: 0px 6px 12px rgba(91, 86, 117, 0.15);
  border-top-left-radius: 12px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  width: fit-content;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-out;
}

.manager_que_card_submenu_container.show {
  visibility: visible;
  opacity: 1;
}

.manager_que_card_submenu {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 0;
  width: 100%;
  margin: 0;
}

.manager_que_card_submenu li {
  display: flex;
  width: 100%;
}

.manager_que_card_submenu_btn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border: 0;
  outline: 0;
  padding: 16px 24px;
  text-align: start;
  white-space: nowrap;
  background-color: transparent;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #2b2744;
}

.manager_que_card_submenu li:last-child .manager_que_card_submenu_btn {
  color: #e96969 !important;
}

.mood_option_container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 48px;
  max-height: 48px;
  border-radius: 12px;
  /* overflow: hidden; */
  margin: 0 8px;
}

.mood_option_label {
  cursor: pointer;
  height: 100%;
  width: 100%;
  border-radius: inherit;
}

.mood_option_input:checked+.mood_option_label {
  border: 2px solid #5153f5;
}

.que_card_que_list {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.que_card_que_list li:not(:last-child) {
  margin-bottom: 1rem;
}

.quick_view_que_modal_text_input {
  border: 0;
  outline: 0;
  width: 100%;
  border-bottom: 1px solid #e4e6f2;
}

.remove_que_modal_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.manager_create_que_header {
  width: 100%;
  padding: 0 4.3%;
  display: flex;
  justify-content: space-between;
}

.create_que_form_container {
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.craete_que_form_title_input {
  border: 0;
  outline: 0;
  border-bottom: 1px solid #e4e6f2;
  width: 100%;
  display: flex;
  background-color: transparent;
  padding: 12px 0 1rem;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #9997a6;
}

.que_type_selector_wrapper {
  position: relative;
  min-width: 280px;
  display: flex;
}

.que_type_selector_options_menu {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  width: 100%;
  list-style-type: none;
}

.que_type_selector_options_menu li {
  display: flex;
}

.que_type_selector_options_menu li button {
  padding: 18px;
  color: #2b2744 !important;
  width: 100% !important;
  justify-content: flex-start !important;
}

.que_type_dropdown_wrapper {
  position: relative;
  width: 280px;
  display: flex;
}

.create_que_input {
  width: 100%;
  padding: 1rem;
  background: #ffffff;
  border: 1px solid #e4e6f2;
  border-radius: 8px;
  outline: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #2b2744;
}

.display_que_card_remove_btn {
  position: absolute;
  right: 24px;
  top: 24px;
}

.view_response_left_wrapper {
  background: #F0F2FE;
}

.view_response_right_wrapper {
  background: #fff;
}

.view_response_placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 84px);
  color: #5153f5 !important;
}

.view_response_left_container {
  background-color: transparent;
  padding: 2rem 4% 2rem 9%;
  height: calc(100vh - 84px);
  overflow-y: auto;
}

.view_response_responders_wrapper {
  margin-top: 1.5rem;
  padding: 1rem 0;
}

.responder_card {
  display: flex;
  flex-direction: unset;
  width: 100%;
  border: 0;
  background-color: #fff;
  align-items: center;
}

.responder_card.active {
  border: 1px solid #5153f5;
  /* box-shadow: 0px 5px 20px rgba(43, 39, 68, 0.15); */
}

.responder_user_container {
  display: flex;
}

.user_card_img_container {
  height: 57px;
  width: 57px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.responder_user_detail_container {
  margin-left: 1rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  /* max-width: 70%; */
  justify-content: center;
}

.view_response_right_container {
  padding: 2rem 6.2% 2rem 28px;
  height: calc(100vh - 84px);
  overflow-y: auto;
}

.view_response_que_wrapper {
  display: flex;
  flex-direction: column;
}

.mood_container {
  height: 80px;
  width: 80px;
  border-radius: 19.05px;
  overflow: hidden;
}

.view_response_answer_wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.view_response_divider {
  margin: 2rem 0;
  /* border-bottom: 1px solid #e4e6f2 !important; */
}

@media screen and (min-width: 1400px) {}

@media screen and (max-width: 1399.99px) {}

@media screen and (max-width: 1199.99px) {}

@media screen and (max-width: 991.99px) {
  .que_active_header {
    flex-direction: column;
    align-items: flex-start;
  }

  .manager_create_que_header {
    flex-direction: column;
    align-items: flex-start;
  }

  .view_response_left_container {
    padding: 2rem 4% 2rem 3.2%;
  }

  .view_response_right_wrapper {
    position: fixed;
    top: 86px;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
  }

  .view_response_right_wrapper.show {
    transform: translateX(0);
  }

  .view_response_right_container {
    padding: 2rem 3% 2rem 12px;
  }

  .view_response_left_wrapper,
  .view_response_right_wrapper {
    padding-right: 0;
  }

  .view_response_left_wrapper.hide {
    display: none;
  }
}

@media screen and (max-width: 767.99px) {
  .manager_que_card {
    display: flex;
    flex-direction: column;
  }

  .que_card_left_detail_container {
    flex-direction: column;
  }

  .que_card_right {
    margin-top: 1.5rem;
    justify-content: space-between;
  }

  .que_draft_card_right {
    margin-top: 1.5rem;
    justify-content: unset;
  }

  .que_prev_header {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media screen and (max-width: 575.99px) {
  .create_que_btn {
    padding: 8px;
    height: 50px;
    width: 50px;
    background: #5153f5;
    border-radius: 50%;
  }

  .add_btn_with_icon_text {
    display: none;
  }

  .manager_create_que_header {
    padding: 0 12px;
  }

  .view_response_left_container {
    padding: 2rem 4% 2rem 0;
  }

  .responder_card {
    padding: 16px;
  }
}

@media screen and (max-width: 400px) {
  .responder_card {
    align-items: flex-start;
  }

  .responder_user_container {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .responder_user_detail_container {
    margin-left: 0;
    margin-top: 1rem;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    max-width: 100%;
    justify-content: center;
  }
}

@media screen and (max-width: 319.99px) {}