.auth_wrapper {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}
.login_form_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  width: 60%;
  margin: 0 auto;
}
.login_form_wrapper {
  margin: 2rem 0 0;
}
.login_image_container {
  background-image: url("../../assets/images/login_logo.png");
  height: 50vh;
  width: 50vw;
  background-size: contain;
  background-repeat: no-repeat;
}
.show_password_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  background-color: transparent;
  margin-right: 12px;
  color: #a6a9b3;
}
.login_page_signup_link_row {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
}
.login_page_signup_link {
  margin-left: 10px;
}
@media screen and (min-width: 1400px) {
}
@media screen and (max-width: 1399.99px) {
}
@media screen and (max-width: 1199.99px) {
  .login_image_container {
    margin-left: auto;
    margin-right: auto;
    background-image: url("../../assets/images/login_logo.png");
    height: 25vh;
  width: 58vw;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .login_form_container {
    width: 80%;
  }
}
@media screen and (max-width: 991.99px) {
  .login_form_container {
    width: 60%;
  }
}
@media screen and (max-width: 767.99px) {
  .login_form_container {
    width: 70%;
  }
}
@media screen and (max-width: 575.99px) {
  .login_form_container {
    width: 80%;
  }
}
@media screen and (max-width: 400px) {
  .login_form_container {
    width: 90%;
  }
}
@media screen and (max-width: 349.99px) {
  .login_page_signup_link_row {
    flex-direction: column;
    align-items: flex-start;
  }
  .login_page_signup_link {
    margin-left: 0px;
    margin-top: 5px;
  }
}
@media screen and (max-width: 319.99px) {
  /* .forgot_psw_row {
    flex-direction: column !important;
    align-items: flex-start !important;
  } */
}
