.employee_card_left {
  display: flex;
  align-items: center;
}
.emp_card_left_detail_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 0 0 1rem;
}

@media screen and (min-width: 1400px) {
}
@media screen and (max-width: 1399.99px) {
}
@media screen and (max-width: 1199.99px) {
}
@media screen and (max-width: 991.99px) {
}
@media screen and (max-width: 767.99px) {
  .employee_card_left {
    flex-direction: column;
  }
  .emp_card_left_detail_container {
    margin: 0;
  }
}
@media screen and (max-width: 575.99px) {
}
@media screen and (max-width: 475px) {
  .employee_card_left {
    width: 100%;
  }
  .emp_card_left_detail_container {
    width: 100%;
  }
  .emp_details_labels {
    flex-direction: column;
    align-items: flex-start;
    /* margin: 0 auto; */
  }
  .emp_details_labels div {
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 400px) {
}
@media screen and (max-width: 319.99px) {
}
