.account_recovery_box {
  padding: 48px 40px 36px !important;
  /* min-height: 500px !important; */
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 450px;
  background: #ffffff;
  border-radius: 15px;
  padding: 24px;
}
.recovery_box_row {
  display: flex;
  align-items: center;
}

@media screen and (min-width: 1400px) {
}
@media screen and (max-width: 1399.99px) {
}
@media screen and (max-width: 1199.99px) {
}
@media screen and (max-width: 991.99px) {
  .recovery_box_row {
    height: 100vh;
    display: flex;
    align-items: center;
  }
}
@media screen and (max-width: 767.99px) {
}
@media screen and (max-width: 575.99px) {
  .account_recovery_box {
    padding: 48px 32px 36px !important;
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 380px;
    background: #ffffff;
    padding: 24px;
  }
}
@media screen and (max-width: 400px) {
  .account_recovery_box {
    padding: 48px 20px 20px !important;
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 280px;
    background: #ffffff;
    box-shadow: 0px 1px 2px rgb(43 39 68 / 15%);
    border-radius: 15px;
    padding: 24px;
    min-height: 480px !important;
  }
}
@media screen and (max-width: 319.99px) {
}
